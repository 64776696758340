// @flow
import React from 'react';
import { getContactHref } from '../../../utils/index';
import styles from './Author.module.scss';
import { useSiteMetadata } from '../../../hooks/index';

const Author = () => {
  const { author } = useSiteMetadata();

  return (
    <div>
      <p>
        <a
          href="/"
          rel="noopener noreferrer"
        >
          back to TOP
        </a>
      </p>
    </div>
  );
};

export default Author;
